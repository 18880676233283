<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="value"
        :label="label"
        prepend-icon="mdi-clock-time-four-outline"
        readonly
        v-bind="attrs"
        :rules="rules"
        clearable
        v-on="on"
        @click:clear="clear"
      ></v-text-field>
    </template>
    <v-time-picker
      v-if="menu"
      ref="timePicker"
      :value="value"
      format="24hr"
      locale="ru-ru"
      @click:hour="onChangeHour"
      @click:minute="onChangeMinute"
    ></v-time-picker>
  </v-menu>
</template>
<script>
export default {
  name: 'DropDownTimePicker',
  props: {
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: null
    },
    min: {
      type: String,
      default: null
    },
    max: {
      type: String,
      default: null
    },
    rules: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data() {
    return {
      menu: false,
      hour: null
    }
  },
  methods: {
    clear() {
      this.menu = false
      this.$emit('input', null)
    },
    onChangeHour(h) {
      this.hour = h
    },
    onChangeMinute(m) {
      this.menu = false
      const h = this.hour ?? 0
      let time = ''

      if (h < 10) {
        time += '0'
      }
      time += h

      time += ':'

      if (m < 10) {
        time += '0'
      }
      time += m
      this.$emit('input', time)
    }
  }
}
</script>
