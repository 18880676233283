export default class OrderManageForm {
  constructor(data) {
    this.shippingAt = data?.shippingAt
    this.managerId = data?.managerId
    this.workerId = data?.workerId
  }

  static create(data) {
    return new OrderManageForm(data)
  }

}
